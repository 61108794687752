import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Landing from './landing';
import Genesis from './genesis';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import './styles/reset.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

ReactDOM.render(
	<Provider store={store}>
		<Router>
			<div>
				<Switch>
					<Route path='/genesis'>
						<Genesis />
					</Route>
					<Route path='/pre-sale'>
						<App />
					</Route>
					<Route path='/'>
						<Landing />
					</Route>
				</Switch>
			</div>
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
