import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from './redux/blockchain/blockchainActions';
import { fetchData } from './redux/data/dataActions';
import * as s from './styles/globalStyles';
import styled, { withTheme } from 'styled-components';

const truncate = (input, len) =>
	input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
	margin: 30px;
	border: solid;
	background-color: Black;
	padding: 10px;
	font-weight: bold;
	color: var(--secondary-text);
	width: 100px;
	cursor: pointer;
`;

export const StyledRoundButton = styled.button`
	padding: 20px;
	border: none;
	background-color: var(--primary);
	padding: 20px;
	font-weight: bold;
	font-size: 15px;
	color: var(--primary-text);
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const ResponsiveWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: stretched;
	align-items: stretched;
	width: 100%;
	@media (min-width: 767px) {
		flex-direction: row;
	}
`;

export const StyledLogo = styled.img`
	@media (max-width: 900px) {
		width: 10%;
		height: auto;
		bottom: 0;
		margin-top: 10%;
	}
	@media (min-width: 1000px) {
		width: 16%;
		position: absolute;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

export const StyledImg = styled.img`
	background-color: var(--accent);
	@media (max-width: 900px) {
		margin-top: 15%;
		width: 60%;
		height: auto;
		top: 0;
		bottom: 0;
	}
	@media (min-width: 1000px) {
		width: 90%;
		height: auto;
		margin-left: 90%;
		margin-top: 20%;
	}
	transition: width 0.5s;
`;

export const StyledLink = styled.a`
	color: var(--secondary);
	text-decoration: none;
`;

export default function Mint() {
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	const [claimingNft, setClaimingNft] = useState(false);
	const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
	const [mintAmount, setMintAmount] = useState(1);
	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: '',
		SCAN_LINK: '',
		NETWORK: {
			NAME: '',
			SYMBOL: '',
			ID: 0,
		},
		NFT_NAME: '',
		SYMBOL: '',
		MAX_SUPPLY: 1,
		WEI_COST: 0,
		DISPLAY_COST: 0,
		GAS_LIMIT: 0,
		MARKETPLACE: '',
		MARKETPLACE_LINK: '',
		SHOW_BACKGROUND: false,
	});

	const claimNFTs = () => {
		let cost = CONFIG.WEI_COST;
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(cost * mintAmount);
		let totalGasLimit = String(gasLimit * mintAmount);
		console.log('Cost: ', totalCostWei);
		console.log('Gas limit: ', totalGasLimit);
		setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
		setClaimingNft(true);
		blockchain.smartContract.methods
			.mint(mintAmount)
			.send({
				gasLimit: String(totalGasLimit),
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once('error', (err) => {
				console.log(err);
				setFeedback('Sorry, something went wrong please try again later.');
				setClaimingNft(false);
			})
			.then((receipt) => {
				console.log(receipt);
				setFeedback(
					`WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
				);
				setClaimingNft(false);
				dispatch(fetchData(blockchain.account));
			});
	};

	const decrementMintAmount = () => {
		let newMintAmount = mintAmount - 1;
		if (newMintAmount < 1) {
			newMintAmount = 1;
		}
		setMintAmount(newMintAmount);
	};

	const incrementMintAmount = () => {
		let newMintAmount = mintAmount + 1;
		if (newMintAmount > 10) {
			newMintAmount = 10;
		}
		setMintAmount(newMintAmount);
	};

	const getData = () => {
		if (blockchain.account !== '' && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account));
		}
	};

	const getConfig = async () => {
		const configResponse = await fetch('/config/config.json', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => {
		getConfig();
	});

	useEffect(() => {
		getData();
	}, [blockchain.account]);

	return (
		<s.Screen>
			<div class='navbar'>
				<ul>
					<li>
						<a href='/genesis'>Genesis</a>
					</li>
					<li>
						<a href='/pre-sale'>Pre-sale</a>
					</li>
					<li>
						<a href=''>Bond</a>
					</li>
					<li>
						<a href=''>Stake</a>
					</li>
					<li>
						<a href=''>Docs</a>
					</li>
				</ul>
			</div>
			<StyledLogo alt={'logo'} src={'/config/images/logop.png'} />
			<s.Container
				flex={1}
				ai={'center'}
				style={{ padding: 0, backgroundColor: 'var(--primary)' }}
			>
				<ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
					<s.Container flex={1} jc={'center'} ai={'center'}>
						<StyledImg alt={'example'} src={'/config/images/imagee.gif'} />
					</s.Container>
					<s.SpacerLarge />

					<s.Container
						flex={2}
						jc={'center'}
						ai={'center'}
						style={{
							marginTop: '10%',
							borderRadius: 0,
						}}
					>
						<s.TextTitle
							class='Ftext'
							style={{
								textAlign: 'center',
								fontSize: 20,
								position: 'reative',
								color: 'var(--accent-text)',
								marginTop: '10px',
								textTransform: 'uppercase',
								fontWeight: '100',
								textDecoration: 'none',
								color: 'white',
								fontFamily: 'Segoe UI',
							}}
						>
							Price per Alpha Centauri
							<span
								class='Ftext'
								style={{
									fontSize: 20,
									color: 'var(--accent-text)',
									marginLeft: '3%',
									position: 'absolute',
								}}
							>
								Alpha Centauri collection
							</span>
						</s.TextTitle>
						<s.TextTitle
							class='Ftext'
							style={{
								textAlign: 'center',
								fontSize: 50,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
							}}
						>
							{CONFIG.DISPLAY_COST} {CONFIG.NETWORK.SYMBOL}{' '}
							<span
								class='Ftext'
								style={{
									fontSize: 50,
									color: 'var(--accent-text)',
									marginLeft: '5%',
									position: 'absolute',
								}}
							>
								{data.totalSupply} / {CONFIG.MAX_SUPPLY}
							</span>
						</s.TextTitle>

						<s.SpacerSmall />
						{Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
							<>
								<s.TextTitle
									style={{ textAlign: 'center', color: 'var(--accent-text)' }}
								>
									The sale has ended.
								</s.TextTitle>
								<s.TextDescription
									style={{ textAlign: 'center', color: 'var(--accent-text)' }}
								>
									You can still find {CONFIG.NFT_NAME} on
								</s.TextDescription>
								<s.SpacerSmall />
								<StyledLink target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
									{CONFIG.MARKETPLACE}
								</StyledLink>
							</>
						) : (
							<>
								>
								<s.SpacerSmall />
								{blockchain.account === '' ||
								blockchain.smartContract === null ? (
									<s.Container
										ai={'center'}
										jc={'center'}
										style={{ paddingLeft: '25%' }}
									>
										<StyledButton
											onClick={(e) => {
												e.preventDefault();
												dispatch(connect());
												getData();
											}}
										>
											CONNECT
										</StyledButton>
										{blockchain.errorMsg !== '' ? (
											<>
												<s.SpacerSmall />
												<s.TextDescription
													style={{
														textAlign: 'center',
														color: 'var(--accent-text)',
													}}
												>
													{blockchain.errorMsg}
												</s.TextDescription>
											</>
										) : null}
									</s.Container>
								) : (
									<>
										<s.TextDescription
											style={{
												textAlign: 'center',
												color: 'var(--accent-text)',
											}}
										>
											{feedback}
										</s.TextDescription>
										<s.SpacerMedium />
										<s.Container ai={'center'} jc={'center'} fd={'row'}>
											<StyledRoundButton
												style={{ lineHeight: 0.4 }}
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													decrementMintAmount();
												}}
											>
												-
											</StyledRoundButton>
											<s.SpacerMedium />
											<s.TextDescription
												style={{
													textAlign: 'center',
													color: 'var(--accent-text)',
												}}
											>
												{mintAmount}
											</s.TextDescription>
											<s.SpacerMedium />
											<StyledRoundButton
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													incrementMintAmount();
												}}
											>
												+
											</StyledRoundButton>
										</s.Container>
										<s.SpacerSmall />
										<s.Container ai={'center'} jc={'center'} fd={'row'}>
											<StyledButton
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													claimNFTs();
													getData();
												}}
											>
												{claimingNft ? 'BUSY' : 'BUY'}
											</StyledButton>
										</s.Container>
									</>
								)}
							</>
						)}
						<s.SpacerMedium />
						<s.SpacerMedium />
					</s.Container>
					<s.SpacerLarge />
					{/* here image  */}
				</ResponsiveWrapper>
				<s.SpacerSmall />
				<s.Container
					jc={'center'}
					ai={'center'}
					style={{ backgroundColor: 'black', marginTop: '10%' }}
					image={'/config/images/space6.jpg'}
				>
					<s.Container
						jc={'center'}
						ai={'center'}
						style={{ width: '50%', marginTop: '10%' }}
					>
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							Phase 0: Origin
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
								marginRight: '50%',
							}}
						>
							Project introduction and pre-sale countdown.
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							Phase I: Alpha Centauri
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
								marginRight: '50%',
							}}
						>
							Pre-sale commences – “whitelisters” are rewarded.
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							Phase II: Aberration
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							End of Alpha Centauri.
							<br /> The Alpha Centauri collection is linked to the beginnings
							of the PrometheusDAO ecosystem. However, the Aberration has begun.
							<br /> Top holders, bottom holders would be awarded the Aberration
							collection. The remainder will be released via a silent launch.
							<br /> Beginning of Andromeda.
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							Phase III: Andromeda
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							Aberration collection holders are rewarded.
							<br /> • Top holders to be rewarded (very) handsomely.
							<br /> • Secured allocation (and airdrop) for DAO-built gaming
							defi ecosystem (in progress – Feb/Mar 2022 launch).
							<br /> • And more…
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							Phase IV: New beginnings
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							Prometheus NFTs represent a permanent form of governance and will
							be awarded PRM tokens.
							<br /> Alpha Centauri holders will be tiered and have access to
							higher staking rewards.
							<br /> Aberration holders will be tiered and have private access
							to Genesis funding rounds.
						</s.TextDescription>
						<s.SpacerMedium />
						<s.SpacerMedium />
					</s.Container>
				</s.Container>
				<s.SpacerMedium />
				<s.SpacerMedium />
				<s.SpacerMedium />
			</s.Container>
		</s.Screen>
	);
}
