import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from './redux/blockchain/blockchainActions';
import { fetchData } from './redux/data/dataActions';
import * as s from './styles/globalStyles';
import styled, { withTheme } from 'styled-components';

const truncate = (input, len) =>
	input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
	margin: 30px;
	border: solid;
	background-color: Black;
	padding: 10px;
	font-weight: bold;
	color: var(--secondary-text);
	width: 100px;
	cursor: pointer;
`;

export const StyledRoundButton = styled.button`
	padding: 20px;
	border: none;
	background-color: var(--primary);
	padding: 20px;
	font-weight: bold;
	font-size: 15px;
	color: var(--primary-text);
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const ResponsiveWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: stretched;
	align-items: stretched;
	width: 100%;
	@media (min-width: 767px) {
		flex-direction: row;
	}
`;

export const StyledLogo = styled.img`
	@media (max-width: 900px) {
		width: 10%;
		height: auto;
		bottom: 0;
		margin-top: 10%;
	}
	@media (min-width: 1000px) {
		width: 16%;
		position: absolute;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

export const StyledImg = styled.img`
	background-color: var(--accent);
	@media (max-width: 900px) {
		margin-top: 15%;
		width: 60%;
		height: auto;
		top: 0;
		bottom: 0;
	}
	@media (min-width: 1000px) {
		width: 90%;
		height: auto;
		margin-left: 90%;
		margin-top: 20%;
	}
	transition: width 0.5s;
`;

export const StyledLink = styled.a`
	color: var(--secondary);
	text-decoration: none;
`;
export default function genesis() {
	return (
		<s.Screen>
			<div class='navbar'>
				<ul>
					<li>
						<a href='/genesis'>Genesis</a>
					</li>
					<li>
						<a href='/pre-sale'>Pre-sale</a>
					</li>
					<li>
						<a href=''>Bond</a>
					</li>
					<li>
						<a href=''>Stake</a>
					</li>
					<li>
						<a href=''>Docs</a>
					</li>
				</ul>
			</div>
			<StyledLogo alt={'logo'} src={'/config/images/logop.png'} href='/' />
			<s.Container
				flex={1}
				ai={'center'}
				style={{ padding: 0, backgroundColor: 'var(--primary)' }}
				image={'/config/images/genesis.gif'}
			></s.Container>
		</s.Screen>
	);
}
